/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React from "react";
import { PropTypes } from "prop-types";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import AppearOnScroll from "~components/AppearOnScroll";
import Footer from "~components/Footer";
import FooterClipped from "~components/FooterClipped";
import HeaderClipped from "~components/HeaderClipped";
import Layout from "~components/Layout";
import SEO from "~components/SEO";
import {
  sortNodes,
  splitStringToParagraphs,
  getAlignClass,
  getWidthClass
} from "~utils/helpers";

const Video = ({ src, title }) => (
  <iframe
    src={src}
    style={{
      position: `absolute`,
      top: 0,
      left: 0,
      width: `100%`,
      height: `100%`
    }}
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
    allowFullScreen
    title={title}
  />
);

const ArtistPage = ({ data, location }) => {
  const sortedNodes = data.allMarkdownRemark.edges.sort(sortNodes);
  const markdown = sortedNodes.find(edge => {
    return edge.node.id === data.markdownRemark.id;
  });

  const { frontmatter } = markdown.node;
  const currentIndex = sortedNodes.indexOf(markdown);

  const nextSlug = sortedNodes[currentIndex + 1]?.node.fields.slug;
  const previousSlug = sortedNodes[currentIndex - 1]?.node.fields.slug;
  const firstGalleryItem = frontmatter.gallery.find(
    item => item.image?.childImageSharp
  );

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        customImage={firstGalleryItem.image.childImageSharp.fluid.src}
        path={location.pathname}
      />

      <Layout className="artist-page w-full relative">
        <section className="artist-page__banner w-full relative pt-48 sm:pt-32 pb-32 sm:pb-16 bg-slate text-off-white">
          <article className="grid">
            <header className="animation-appear-right grid-end-6 md:grid-end-8 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 relative flex xs:flex-col items-end xs:items-start justify-between mb-4 border-b-white text-white">
              <h1 className="f1">{frontmatter.title}</h1>
              <h2 className="b2 uppercase">{frontmatter.role}</h2>
            </header>

            <h3 className="animation-appear-right animation-delay-1 grid-end-5 md:grid-end-7 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 mt-8 sm:mt-4 f1">
              {frontmatter.headline}
            </h3>

            <div className="artist-page__content animation-appear-right animation-delay-2 grid-end-3 md:grid-end-4 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 mt-8 whitespace-pre-wrap b2">
              {splitStringToParagraphs(frontmatter.intro, 0)}

              {(frontmatter.artistWebsite || frontmatter.artistInstagram) && (
                <br />
              )}

              {frontmatter.artistWebsite && frontmatter.artistWebsite !== `` && (
                <h3 className="my-2 b2">
                  <a
                    href={frontmatter.artistWebsite}
                    className="hover-underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Website - {frontmatter.artistWebsite}
                  </a>
                </h3>
              )}

              {frontmatter.artistInstagram &&
                frontmatter.artistInstagram !== `` && (
                  <h3 className="my-2 b2">
                    <a
                      href={frontmatter.artistInstagram}
                      className="hover-underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram - {frontmatter.artistInstagram}
                    </a>
                  </h3>
                )}
            </div>
          </article>
        </section>

        {frontmatter.gallery && frontmatter.gallery.length > 0 && (
          <section className="w-full relative py-24 sm:pt-12 sm:pb-12 bg-white text-slate">
            <HeaderClipped />
            <FooterClipped />

            <AppearOnScroll once>
              <div className="grid">
                <ul className="grid-end-6 md:grid-end-8 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 relative">
                  {frontmatter.gallery.map((galleryItem, index) => {
                    const key = `${frontmatter.title}-gallery-item-${index}`;
                    const alignClass = getAlignClass(galleryItem.align);
                    const widthClass = getWidthClass(galleryItem.width);

                    return (
                      <li key={key} className={`w-full flex ${alignClass}`}>
                        <div
                          className={`${widthClass} relative block mb-16 sm:mb-4`}
                        >
                          {galleryItem.image &&
                            !galleryItem.youtubeId &&
                            !galleryItem.vimeoId && (
                              <Img
                                fluid={galleryItem.image.childImageSharp.fluid}
                                alt={key}
                              />
                            )}
                          {(galleryItem.youtubeId || galleryItem.vimeoId) && (
                            <div className="video">
                              <div
                                style={{
                                  padding: `75% 0 0 0`,
                                  position: `relative`
                                }}
                              >
                                <Video
                                  src={
                                    galleryItem.youtubeId
                                      ? `https://www.youtube.com/embed/${galleryItem.youtubeId}?autoplay=1&loop=1`
                                      : `https://player.vimeo.com/video/${galleryItem.vimeoId}?autoplay=1&title=0&byline=0&portrait=0&loop=1`
                                  }
                                  title={key}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="navigation-links">
                <h3 className="b2">
                  {previousSlug && (
                    <Link to={previousSlug} className="link">
                      Previous
                    </Link>
                  )}
                  <span>|</span>
                  <Link to={nextSlug} className="link">
                    Next
                  </Link>
                </h3>
              </div>
            </AppearOnScroll>
          </section>
        )}
      </Layout>

      <Footer />
    </>
  );
};

ArtistPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({})
    })
  }).isRequired,
  location: PropTypes.shape({}).isRequired
};

export default ArtistPage;

export const query = graphql`
  query ArtistPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "artist-page" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date
            title
            role
            headline
            intro
            gallery {
              align
              image {
                childImageSharp {
                  fluid(maxWidth: 5120, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              width
              youtubeId
              vimeoId
            }
            artistWebsite
            artistInstagram
            seoDescription
            seoKeywords
          }
        }
      }
    }
  }
`;
